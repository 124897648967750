import ClipLoader from 'react-spinners/ClipLoader';

export default function ProductSpinner() {
  return (
    <div className="spinner-container">
      <ClipLoader
        color="green"
        loading={true}
        // cssOverride={override}
        size={150}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
}
