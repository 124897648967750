'use client';

import Link from 'next/link';
import { usePathname } from 'next/navigation';
// import { useRouter } from "next/router";
import { useEffect, useState } from 'react';

export default function ActiveLink({
  className = '',
  activeClassName = 'active',
  children,
  ...props
}) {
  const asPath = usePathname();
  const [computedClassName, setComputedClassName] = useState(className);

  useEffect(() => {
    const linkPathname = new URL(props.as || props.href, location.href)
      .pathname;

    const activePathname = new URL(asPath, location.href).pathname;

    const newClassName =
      linkPathname === activePathname
        ? `${className} ${activeClassName}`.trim()
        : className;

    if (newClassName !== computedClassName) {
      setComputedClassName(newClassName);
    }
    // }
  }, [
    asPath,
    props.as,
    props.href,
    activeClassName,
    className,
    computedClassName,
  ]);

  return (
    <Link {...props} className={computedClassName}>
      {children}
    </Link>
  );
}
