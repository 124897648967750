'use client';
import Image from 'next/image';
import Product from '@/components/Main/shared/Product';
import ArrowSvg from '@/svg/arrow.svg';
import catalogImg from '@/imgs/catalog_auto.png';
import Link from 'next/link';
import ProductSpinner from './ProductSpinner';
import useSWR from 'swr';

const text = {
  title: 'АВТО ИЗ-ЗА ГРАНИЦЫ В РОССИЮ',
  text: 'Цены под ключ указаны со всеми расходами, включая ремонт и постановку на учет',
};

export default function HeroBlock({ limit = 10 }) {
  const { data = { cars: [] }, isLoading } = useSWR(
    `/api/cars?limit=${limit}`,
    (url) => fetch(url).then((r) => r.json())
  );
  const { cars } = data;
  const spinnerCards = Array.from({ length: limit }, (_, index) => (
    <ProductSpinner key={index} />
  ));
  return (
    <div className="hero_cars_block">
      <div className="padd-container">
        <div className="white-bordered">
          <div className="hot-cars__title">
            <h2>{text.title}</h2>
            <p>{text.text}</p>
          </div>

          <div className="cars-container">
            {!isLoading && cars
              ? cars.map((item, i) => {
                  return <Product car={item} key={i} hot={item.hot} />;
                })
              : spinnerCards}
          </div>

          <Link className="catalog_block btn-anim" href="/catalog">
            <div className="info">
              <p>ПОКАЗАТЬ ВСЕ АВТОМОБИЛИ</p>
              <span className="icon">
                <ArrowSvg width="46" height="44" />
              </span>
            </div>
            <div className="img">
              <Image src={catalogImg.src} alt="tg_holder" fill />
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}
